#RSVPApp .Green {
  color: green !important;
}

#RSVPApp .Red {
  color: red !important;
}

#RSVPApp .Gray {
  color: #888888;
}


#RSVPApp.App {
  color: #ffffff;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
}

#RSVPApp  .Step_Header {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

#RSVPApp .SearchInput_Container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}


#RSVPApp .SearchInput_Input {
  width: 100%;
  font-size: 1.5rem;
  padding: 6px 8px;
  text-align: center;
  color: #444444;
  border: 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 40px;
  font-size: 24px;
}

#RSVPApp .SearchInput_Button, .SaveRSVP_Button {
  margin: 1rem auto 0;
  padding: 0.375rem 1rem;
  font-size: 1.25rem;
  font-family: serif;
  text-transform: uppercase;
  background-color: #333;
  color: #ffffff;
  border: 0px;
  min-width: 132px;
  line-height: 1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: background-color 50ms 200ms ease-in;
  height: 40px;

}

#RSVPApp .SaveRSVP_Button {
  margin-top: 0px;
  width: auto;
  padding: 0.375rem 2rem;
  background-color: #D4AF57;
}

#RSVPApp .SaveRSVP_Button:hover {
  background-color: #BC9230;
  cursor: pointer;
}

#RSVPApp .SaveRSVP_Button:disabled {
  background-color: #e9d5a7;
}

#RSVPApp .SaveRSVP_Button:disabled:hover {
  cursor: auto;
}

#RSVPApp .SearchInput_BackButton {
  padding: 0.375rem 1rem;
  font-size: 1.25rem;
  background-color: #333;
  color: #ffffff;
  border: 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: background-color 50ms 200ms ease-in;
  height: 40px;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  order: 1;
  margin-top: 0.75rem;
}

#RSVPApp .SearchInput_Button {
  order: 2;
  font-size: 20px;
}

#RSVPApp .SearchInput_Button:hover {
  background-color: #111;
  cursor: pointer;
}


#RSVPApp .StepTwo_Container .SearchInput_Container,
#RSVPApp .StepThree_Container .SearchInput_Container {
  flex-direction: row;
    width: 100%;
    max-width: 500px;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 50px;
    flex-wrap: wrap;
}

#RSVPApp .StepTwo_Container .SearchInput_Input {
  width: 100%;
  max-width: none;
  flex-grow: 2;
  font-size: 24px;
}

#RSVPApp .StepOne_Container .SearchInput_Button {
  width: auto;
  padding: 0.375rem 2rem;
}



#RSVPApp .StepTwo_Container .SearchInput_Button, #RSVPApp .StepThree_Container .SearchInput_Button {
    margin: 0.75rem 0px 0px 0px;
}

#RSVPApp .StepThree_Container .SearchInput_Input {
  flex-grow: 2;
}


#RSVPApp .StepTwo_Container .PersonList_Container {
  max-width: 500px;
  margin: 0 auto;
  margin-top: 1.5rem;
  background-color: #ffffff;
  color: #222222;
  max-height: calc(100vh - 400px);
  overflow-y: auto;
}

  #RSVPApp .StepTwo_Container .PersonListItem_Container {
    border-bottom: 1px solid #ccc;
  }

  #RSVPApp .StepTwo_Container .PersonListItem_Container:last-child {
    border: none;
  }

  #RSVPApp .StepTwo_Container .PersonListItem,
  #RSVPApp .StepTwo_Container .PersonList_NoResults {
        cursor: pointer;
    font-size: 24px;
    padding: 16px 0px;
    line-height: 1.4;
  }

  #RSVPApp .StepTwo_Container .PersonList_NoResults {
    cursor: default;
  }

  #RSVPApp .StepTwo_Container .PersonListItem:hover {
    background-color: #efefef;
  }

#RSVPApp .RSVPPersonList_Container {
    width: 100%;
    max-width: 992px;
    background-color: #ffffff;
    margin: 0px auto 1rem;
    display: flex;
    flex-wrap: wrap;
    padding: 4px 12px;
        max-height: calc(100vh - 400px);
    overflow-y: auto;
}

#RSVPApp .RSVPPersonListItem_Container {
  width: 100%;
  flex-grow: 2;
  color: #222222;
  border-bottom: 1px solid #eeeeee;
}

  /* #RSVPApp .RSVPPersonListItem_Container:nth-last-of-type(-n+2):not(:nth-child(even)) {
    flex-grow: 0;
    margin: 0 auto;
  } */



/* 
  #RSVPApp .RSVPPersonListItem_Container:nth-child(odd):not(:last-child) .RSVPPersonListItem {
    border-right: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
  } */

  #RSVPApp .RSVPPersonListItem_Container:nth-child(even):not(:last-child) .RSVPPersonListItem {
    /* border-bottom: 1px solid #eeeeee; */
  }

#RSVPApp .RSVPPersonListItem {
  padding: 1rem 0rem 1.1rem 0rem;
}

#RSVPApp .RSVPPersonListItem_HeaderRow {
  display: flex;
  align-items: center;
}

#RSVPApp .RSVPPersonListItem_RSVPInput {
  display: none;
}

#RSVPApp .RSVPPersonListItem_RSVPButton {
  font-size: 28px;
    margin-right: 8px;
    color: #aaaaaa;
}

#RSVPApp .RSVPPersonListItem_RSVPButton_Yes.Selected {
  color: green;
}

#RSVPApp .RSVPPersonListItem_RSVPButton_Yes:hover {
  color: green;
}

#RSVPApp .RSVPPersonListItem_RSVPButton_No.Selected {
  color: red;
}

#RSVPApp .RSVPPersonListItem_RSVPButton_No:hover {
  color: red;
}

#RSVPApp .RSVPPersonListItem_RSVPButtons {
  min-width: 72px;
}

#RSVPApp .RSVPPersonListItem_Name {
  margin-left: 8px;
  margin-top: -8px;
  
}

#RSVPApp .RSVPPersonListItem_Name p {
  line-height: 1;
  font-family: serif;
  text-align: left;
    font-size: 32px;

}

#RSVPApp .RSVPPersonListItem_GuestName {
      display: flex;
    margin-left: 80px;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 4px;
}

#RSVPApp .RSVPPersonListItem_GuestName_First,
#RSVPApp .RSVPPersonListItem_GuestName_Last {
  width: 100%;
    display: flex;
    flex-direction: column;
}

#RSVPApp .RSVPPersonListItem_GuestName_First label,
#RSVPApp .RSVPPersonListItem_GuestName_Last label {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
}
#RSVPApp .RSVPPersonListItem_GuestName_First input,
#RSVPApp .RSVPPersonListItem_GuestName_Last input {
  border: 1px solid #cccccc;
    border-radius: 0px;
    font-size: 16px;
    padding: 6px;
        text-transform: capitalize;
    letter-spacing: 1px;
}


#RSVPApp .RSVPPersonListItem_FoodRow {
    display: flex;
    margin-left: 80px;
    flex-wrap: wrap;
    margin-top: 2px;
}

#RSVPApp .RSVPPersonListItem_FoodRow div {
  margin-top: 8px;
  font-weight: normal;
}

#RSVPApp .RSVPPersonListItem_FoodRow input {
  position: relative;
    margin-right: 6px;
    width: 15px;
    height: 15px;
    margin-top: -1px;
}

#RSVPApp .RSVPPersonListItem_FoodRow div:not(:last-child) {
  margin-right: 1rem;
}

#RSVPApp .RSVPPersonListItem_FoodRow input + label {
  font-size: 20px;
    line-height: 1;
    font-weight: normal;
    margin-bottom: 0px;
}

#RSVPApp .RSVPPersonListItem_FoodRow input + label:hover {
  cursor: pointer;
}

#RSVPApp .RSVPPersonListItem_FoodRow_Item input[disabled]+label:hover {
  cursor: default;
}

#RSVPApp .RSVPPersonListItem_FoodRow_Item {
  text-align: left;
    display: flex;
    align-items: center;
}

#RSVPApp .RSVPPersonListItem_FoodRow_Item:hover {
  cursor: pointer;
}

#RSVPApp .RSVPPersonListItem_FoodRow_Item.NotSelected input + label {
  color: #aaaaaa;
}

#RSVPApp .RSVPPersonListItem_FoodRow_Item.NotSelected:not(.Disabled) input + label:hover,
#RSVPApp .RSVPPersonListItem_FoodRow_Item:not(.NotSelected) input:hover {
  cursor: pointer;
  color: #D4AF57;
}

#RSVPApp .RSVPPersonListItem_FoodRow_Item input[disabled] + label {
  color: #aaaaaa;
}

#RSVPApp .StepFour_Container {
  color: #222222;
  width: 100%;
  max-width: 992px;
  background-color: #ffffff;
  margin: 1rem auto;
  line-height: 1.3;
}

#RSVPApp .StepFour_Container .Step_Header {
  color: #222222;
}

#RSVPApp .StepFour_Text {
  line-height: 1.6;
}

#RSVPApp .ErrorContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -38px;
  margin-bottom: 8px;
}

#RSVPApp .ErrorText {
  background: #ffffff;
  padding: 6px 18px;
  border-radius: 5px;
  font-size: 18px;
  color: red;
}

#RSVPApp .ErrorTextIcon {
  color: red;
}

@media screen and (min-width: 400px) {
  #RSVPApp .RSVPPersonListItem_Container {
    min-width: 350px;
  }

  #RSVPApp .RSVPPersonListItem_GuestName_First, #RSVPApp .RSVPPersonListItem_GuestName_Last {
    width: 48%;
  }
}

@media screen and (min-width: 700px) {
  #RSVPApp .SearchInput_Input {
    max-width: 400px;
  }

  #RSVPApp .SearchInput_BackButton {

    order: 0;
    margin-top: 0px;
  }

  #RSVPApp .SearchInput_Button {
    order: 0;
  }
}

@media screen and (min-width: 768px) {
  #RSVPApp .SearchInput_BackButton {
    display: flex;
  }


  #RSVPApp .SearchInput_Input {
    width: 50%;
  }
  
  #RSVPApp .SearchInput_Button, .SaveRSVP_Button {
    width: 30%;
  }
  
  #RSVPApp .StepTwo_Container .SearchInput_Container, #RSVPApp .StepThree_Container .SearchInput_Container {
    flex-wrap: nowrap;
  }

  #RSVPApp .StepTwo_Container .SearchInput_Input {
    width: auto;
  }

  #RSVPApp .StepTwo_Container .SearchInput_Button,
#RSVPApp .StepThree_Container .SearchInput_Button {
  margin: 0px 0px 0px 0.5rem ;
}



  #RSVPApp .RSVPPersonListItem {
      padding: 1rem 1rem 1.1rem 1rem;
  }

  #RSVPApp .StepFour_Container {
    padding: 1.5rem 1.5rem 1.75rem 1.5rem;
  }
}

@media screen and (min-width: 822px) {
  #RSVPApp .RSVPPersonListItem_Container:nth-child(odd){
    border-right: 1px solid #eeeeee;
    width: calc(50% - 1px);
  }

  #RSVPApp .RSVPPersonListItem_Container {
    width: 50%;
  }
}